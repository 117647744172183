@charset "utf-8";
@import 'partials/bootstrap.custom.min';
@import 'partials/_variables';


/* only Grid system CSS */


/* --------------------------------
    Common style
-------------------------------- */

html {
    font-size: 62.5%;
    /* 62.5%を指定すると「1.0 rem = 10px」 */
}

body {
    font-size: 16px;
    font-size: 1.6rem;
}

a {
    color: #242121;
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus,
a:active {
    color: #242121;
    text-decoration: none;
    outline: none;
}

textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

dl,
dt,
dd,
li {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    height: auto;
}



/* svg icon */

#svgicon {
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
}

.cb {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
}

.cb-images {
    width: 1.125em;
}

.icon-bg {
    fill: #d4d4d5;
}

.icon-with-bg {
    fill: #fff;
}

.container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
}

.row {
    margin-left: -8px;
    margin-right: -8px;
    padding-bottom: 16px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    padding-left: 8px;
    padding-right: 8px;
}



/*  table
============================ */

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd
}

.table-bordered {
    border: 1px solid #ddd
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td {
    border: 1px solid #ddd
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
    border-bottom-width: 2px
}



/*  form
============================ */

label {
    font-weight: normal;
}

input:focus,
select:focus {
    outline: none;
}

input[type="number"] {
    text-align: right;
}

.form-group .errormsg {
    margin-top: 3px;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: bold;
}

dd.form-group .errormsg {
    margin-bottom: 0;
}

.form-control {
    border: solid 1px #e0e0e0;
    border-radius: 5px !important;
    box-shadow: none;
    font-size: 16px;
    font-size: 1.6rem;
    /*height: 40px;*/
    padding: 10px 12px;
    width: auto;
    line-height: 1.5;
    margin: 0 0 10px 0;
}

.form-control:focus {
    border-color: #3c8dbc !important;
    box-shadow: none
}

.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus {
    box-shadow: none;
    background: none;
}

.has-success .form-control {
    border-color: #0D8969;
    background: #e7f6f3;
}

.has-warning .form-control {
    border-color: #E99133;
    background: #fdf7ed;
}

.has-error .form-control {
    border-color: #CF3F34;
    background: #FDF1F0;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
    color: inherit;
    background: #FDF1F0;
}

.form-control::-moz-placeholder {
    color: #bbb;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #bbb;
}

.form-control::-webkit-input-placeholder {
    color: #bbb;
}

.form-control:not(select) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-group .form-control {
    width: 100%;
}

.form-inline .form-control {
    width: auto;
}

.form-group.lange input[type="text"],
.form-group.lange label {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 1%;
    margin-right: 1%;
    vertical-align: middle;
}

.form-group.lange input[type="text"] {
    width: 28%;
}

.input_tel input {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 1px;
    margin-right: 1px;
    max-width: 5em;
    vertical-align: middle;
    width: 30%;
}

.form-group.input_tel input:first-child {
    margin-left: 0;
}

.form-group.input_name input {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 2%;
    vertical-align: middle;
    width: 48%;
}

.form-group.input_name input:first-child {
    margin-left: 0;
}

.form-group.input_zip input {
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
    max-width: 5em;
    vertical-align: middle;
    width: 30%;
}

.form-group.input_zip .question-circle {
    font-size: 11px;
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    vertical-align: middle;
    background: #525263;
    border-radius: 50%;
}

.form-group.input_zip .question-circle .cb-question {
    fill: #fff;
}

.form-group .item_price input,
.form-group .item_quantity input {
    margin: 0 1%;
    text-align: right;
    width: 50%;
}

.form-inline label {
    margin: 0 10px 0 0;
}

.form-inline label:first-child+select,
.form-inline label:first-child+input {
    margin-right: 10px;
}

.form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
}

.checkbox-inline,
.radio-inline {
    cursor: pointer;
    margin-left: 20px;
}

.checkbox-inline:first-child,
.radio-inline:first-child {
    margin-left: 0;
}

span.required {
    color: #df744a;
    font-weight: normal !important;
    margin-left: 1em;
    font-size: 12px;
    font-size: 1.2rem;
}



/*clearfix*/
.footer_link,
.item_photo_area,
#search:after,
#member:after,
.right_icon:after,
#shopping_flow ol:after,
#member:after,
.right_icon:after,
.footer_top:after,
.cart_area:after,
.top4:after,
.top10:after,
#detail_wrap:after,
#new_arrival:after,
.pick_up_contents:after,
.header_nav:after,
.pc_inn:after,
ul:after,
li:after,
#item_list:after,
#pick_up:after,
dl:after,
dt:after,
header:after,
.drawer:after {
    content: "";
    display: table;
    clear: both;
}



/* --------------------------------
    Parts
-------------------------------- */


/* heading */

h2 {
    font-size: 16px;
    font-size: 1.6rem;
}

.page-heading {
    margin: 0 0 8px;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    border-top: 1px solid #e0e0e0;
    padding: 8px;
}

.heading01 {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
}

.heading02,
.heading03 {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
}

.heading04 {
    clear: both;
    margin-bottom: 5px;
}

.heading05 {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
}



/* btn */

.btn {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 0;
}

.btn-lg {
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.3333333
}

.btn-sm {
    padding: 8px 10px;
    font-size: 12px;
    line-height: 1.5;
    font-weight: normal;
}

.btn-xs {
    padding: 5px 8px;
    font-size: 10px;
    line-height: 1.5;
    font-weight: normal;
}

.btn .cb {
    margin: 0 5px;
}



/* btn_circle */

.btn_circle {
    border: 0 none;
    padding: 0;
    margin: 0;
    text-shadow: none;
    box-shadow: none;
    border-radius: 50%;
    color: #fff;
    background: #626264;
    cursor: pointer;
    width: 40px;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
}

.btn_circle .cb {
    font-size: 24px;
    font-size: 2.4rem;
    position: relative;
    top: -1px;
}

.btn_circle:hover {
    opacity: 0.8;
}



/* icon_close */

.icon_close {
    /* container for the X icon */
    position: absolute;
    height: 100%;
    width: 50px;
    top: 0;
    left: 0;
}

.icon_close::before,
.icon_close::after {
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    /* close icon */
    content: '';
    position: absolute;
    display: inline-block;
    height: 3px;
    width: 22px;
    top: 50%;
    margin-top: -2px;
    left: 50%;
    margin-left: -11px;
    background: #525263;
}

.icon_close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.icon_close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}



/* badge */

.badge {
   display: inline-block;
   min-width: 10px;
   padding: 3px 6px;
   font-size: 1rem;
   font-weight: 700;
   line-height: 1;
   color: #626265;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   background-color: #fff;
   border-radius: 10px;
}

.btn .badge {
    position: relative;
    top: -1px
}



/* tableStyle */

div.table {
    width: 100%;
    display: table;
}

div.table .thead {
    display: none;
}

div.table .tbody {
    display: table-row-group;
}

div.table .tbody .tr {
    display: table-row;
    margin: 0;
}

div.table .tbody .tr>.td {
    display: table-cell;
    padding: 10px 8px;
    text-align: center;
    overflow-x: hidden;
}

.table .tbody .tr>.price {
    text-align: right;
}



/* dl_table */

.dl_table {
    border-top: 1px dotted #e0e0e0;
}

.dl_table dl {
    padding: 8px 0;
    border-bottom: 1px dotted #e0e0e0;
}

.dl_table dd .form-group {
    padding-top: 8px;
}

.dl_table dd>.form-group:first-child {
    padding-top: 0;
}

.dl_table .zip-search {
    margin-top: 8px;
}



/* pagination */

.pagination {
    width: 95%;
    margin: 16px auto;
    position: relative;
    clear: both;
}

.pagination ul {
    list-style-type: none;
    margin: 0 auto;
    padding: 1em 0;
    text-align: center;
}

.pagination ul li {
    display: inline-block;
    min-width: 29px;
    padding: 0 3px 0 2px;
    text-align: center;
    position: relative;
}

.pagination ul li a {
    color: #525263;
    display: block;
    line-height: 1.8;
    padding: 5px 1em;
    text-decoration: none;
}

.pagination ul li a:hover,
.pagination ul li a:focus {}

.pagination ul li.active a {
    background: url(../img/common/bg_gray.png);
    text-decoration: none;
    border-radius: 50%;
}


.pagination ul li.disabled a {
    color: #9797A0;
    text-decoration: none;
}

.pagination ul li.disabled a:hover,
.pagination ul li.disabled a:focus {
    color: #9797A0;
}



/* topicpath */

#topicpath {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px dotted #e0e0e0;
    padding: 0 8px;
}

#topicpath ol {
    padding-top: 1em;
    padding-bottom: 1em;
    list-style: none;
    overflow: hidden;
}

#topicpath li {
    float: left;
    color: #333;
    font-size: 12px;
    font-size: 1.2rem;
}

#topicpath li a {
    float: left;
    display: inline-block;
}

#topicpath li a:after {
    content: "｜";
    padding: 0 10px;
}

#topicpath li:last-child a {
    font-weight: bold;
}

#topicpath li:last-child a:after {
    content: "";
}



/* message */

.message {
    padding: 16px 0;
    margin: 0;
}

p.message {
    padding: 0 0 16px;
}

.heading04+.message {
    padding-top: 0;
    text-align: left;
}

.message .errormsg {
    padding: 8px;
    font-weight: bold;
}

.message .errormsg:first-child {
    margin-top: 0;
}

.message .errormsg:last-child {
    margin-bottom: 0;
}

.message .errormsg .cb {
    margin-right: 5px;
    font-size: 130%;
    position: relative;
    top: -1px;
}



/* pagenumberarea */

.pagenumberarea {
    text-align: right;
    margin: 0;
    padding-right: 16px;
    padding-bottom: 20px;
}

.pagenumberarea>li {
    float: none;
    display: inline-block;
    margin-left: 3px;
    width: auto;
}

.dropdown.open>a {
    background: #ededed;
}



/* flowline */

.flowline {
    position: relative;
    margin: 0;
    padding: 8px 0 16px;
    border-bottom: 1px dotted #e0e0e0;
}

.flowline ul {
    position: relative;
    margin: 0 auto;
}

.flowline ul::before {
    content: "";
    height: 4px;

    background: #525263;
    position: absolute;
    top: 13px;
    margin: 0;
    border-radius: 2px;
    z-index: 0;
}

.flowline.step3 ul::before {
    width: 66.66666666%;
    left: 16.6666666%;
}

.flowline.step4 ul::before {
    width: 75%;
    left: 12.5%;
}

.flowline ul li {
    float: left;
    position: relative;
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: bold;
    z-index: 1;
}

.flowline.step3 ul li {
    width: 33.33333333333333%;
}

.flowline.step4 ul li {
    width: 25%;
}

.flowline li .flow_number {
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    font-size: 1.2rem;
    background: #525263;
    color: #fff;
    top: 0;
    left: 18px;
    display: inline-block;
    margin-bottom: 5px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
}

.flowline li.active {
    color: #5CB1B1;
}

.flowline li.active .flow_number {
    background: #5CB1B1;
}



/* item_box */

.item_box>div {
    padding: 8px;
}

.item_box .item_photo {
    max-width: 100px;
}

.item_box .icon_edit {
    position: absolute;
    top: 16px;
    right: 16px;
    text-align: right;
    padding: 0;
}



/* .cart .item_box */

.cart_detail {
    padding: 0 8px;
}

.cart .item_box {
    border-bottom: 1px solid #E8E8E8;
    position: relative;
}

.cart .item_box:last-child {
    border: 0 none;
}

.cart .item_box>div,
.cart .item_box>dl {
    padding: 8px 0;
}

.cart .item_box .item_photo {
    padding: 8px 8px 8px 0;
    float: left;
    width: 40%;
}

.cart .item_box .item_detail {
   float: left;
   width: 68%;
}

.cart .item_box .icon_edit {
    height: 30px;
    width: 30px;
    right: 0;
    top: 10px;
    text-align: center;
    vertical-align: middle;
}

.cart .item_box .icon_edit button {
    display: inline-block;
    vertical-align: middle;
}

.cart .item_box .icon_close {
    width: 30px;
}

.cart .item_box .cb {
    font-size: 20px;
    font-size: 2rem;
}

.cart .item_box .item_name {
    margin-right: 35px;
    font-size: 16px;
    font-size: 1.6rem;
}

.cart .item_box .item_pattern {
    font-size: 14px;
    font-size: 1.4rem;
}

.cart .item_box .item_price {
   font-size: 2.4rem;
}

.cart .item_box .item_price .small {
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 10px;
}

.cart .item_box .item_quantity {
    vertical-align: middle;
    font-size: 14px;
    font-size: 1.4rem;
}

.cart .item_box .item_quantity input {
    font-size: 16px;
    font-size: 1.6rem;
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    text-align: right;
}

.cart .cart_price {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 8px;
    text-align: right;
}

.cart .cart_price .price {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
}

.cart .btn_area {
    padding: 8px 0 0;
}

.cart .btn_area li {
    padding-bottom: 8px;
}

.cart .btn_area .btn {
    width: 100%;
}

.cart .btn_area .btn-sm {
    height: auto;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: 10px 8px;
        border-color: #df744a;
}



/* form_terms_link */

.form_terms_link {
    text-align: center;
}



/* error_box */

.error_box {
    text-align: center;
    padding: 5% 0;
}

.error_box .btn_back {
    padding: 5% 0;
}

.error_box .btn_back a {
    width: 100%;
    max-width: 82%;
}



/* --------------------------------
    Common
-------------------------------- */


/****** Base Layout Setting ********/

#contents,
#header,
#footer,
.pagetop {
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
}

#contents,
#footer,
.pagetop {
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}

#contents {
    min-height: 100vh;
    z-index: 2;
}

#header .container {
    position: relative;
}



/* main */

#main {
    margin: 0 0 40px 0;
}

#sub_area {
    margin: 0 0 20px 0;
}

#relative_category_box {
    border-bottom: solid 1px #e0e0e0;
    border-top: solid 1px #e0e0e0;
    margin: 0 0 20px 0;
    padding: 20px 2%;
}



/* side */

.side {}



/****** Header Setting ********/


/*SP下層ページヘッダー*/

.header {
    z-index: 100;
    min-height: 45px;
    position: absolute;
    width: 100%;
    background: rgba(149, 184, 176, 0.85);
}



/*SPトップページヘッダー*/

#page_homepage {
    .header {
        z-index: 100;
        min-height: 45px;
        position: absolute;
        width: 100%;
        background: transparent;
    }
}

#header .copy {
    font-size: 12px;
    font-size: 1.2rem;
    margin: 0;
    padding: 2px 0 0 0;
    color: #fff;
}

.header_logo_area {
    position: relative;
    z-index: 10;
    min-height: 54px;
    padding: 2%;
}

.header_logo_area .header_logo {
    font-size: 20px;
    font-size: 2rem;
    margin: 0;
    width: 50%;
}



/* header-buttons */

.header-buttons {
    position: absolute;
    display: inline-block;
    top: 3px;
    right: 5%;
    background: #999;
    z-index: 11;
}

.header-buttons li {
    display: inline-block;
}

.cart-trigger,
.nav-trigger {
    position: relative;
    display: block;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
    z-index: 3;
    cursor: pointer;
}

.nav-trigger {
    color: transparent !important;
}

p.cart-trigger a:link,
p.cart-trigger a:visited,
p.cart-trigger a:hover,
p.cart-trigger a:active {
    text-decoration: none;
    color: #fff;
}

p.cart-trigger:hover {
    opacity: 0.8;
}

p.cart-trigger a {
    display: inline-block;
    width: 50px;
    height: 44px;
    /* line-height: 44px; */
    vertical-align: middle;
    text-align: center;
    outline: none;
}

p.cart-trigger .cb {
 vertical-align: middle;
 font-size: 30px;
 transition: opacity 0.3s, transform 0.3s;
 position: absolute;
 left: 0px;
 top: 2px;
}

p.cart-trigger .cb-close {
    opacity: 0;
    position: absolute;
    left: 13px;
    top: 3px;
    font-size: 18x;
}

p.cart-trigger .badge {
    position: absolute;
    right: 0px;
    top: 0px;
}

p.cart-trigger.cart-is-visible .cb-shopping-cart,
p.cart-trigger.cart-is-visible span.badge {
    /* hide cart icon */
    opacity: 0;
}

p.cart-trigger.cart-is-visible .cb-close {
    /* show close icon */
    opacity: 1;
}

#btn_menu {
    margin: 0;
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: 11;
}

.nav-trigger span,
.nav-trigger span::before,
.nav-trigger span::after {
    /* hamburger icon in CSS */
    position: absolute;
    display: inline-block;
    height: 2px;
    width: 35px;
    background: #fff;
}

.nav-trigger span {
    /* line in the center */
    position: absolute;
    top: 60%;
    right: 5px;
    margin-top: -2px;
    -webkit-transition: background 0.3s 0.3s;
    -moz-transition: background 0.3s 0.3s;
    transition: background 0.3s 0.3s;
}

.nav-trigger span::before,
.nav-trigger span::after {
    /* other 2 lines */
    content: '';
    right: 0;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    -moz-transition: -moz-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
}

.nav-trigger span::before {
    /* menu icon top line */
    top: -10px;
}

.nav-trigger span::after {
    /* menu icon bottom line */
    top: 10px;
}

.drawer-open .nav-trigger span {
    /* hide line in the center */
    background: rgba(46, 50, 51, 0);
}

.drawer-open .nav-trigger span::before,
.drawer-open .nav-trigger span::after {
    /* keep visible other 2 lines */
    background: #fff;
    ;
}

.drawer-open .nav-trigger span::before {
    -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
    transform: translateX(4px) translateY(-3px) rotate(45deg);
}

.drawer-open .nav-trigger span::after {
    -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
    transform: translateX(4px) translateY(2px) rotate(-45deg);
}




/*  Category navigation
--------------------------- */


/* category-nav */

#drawer .category-nav {
    background: #fff;
}

#drawer .category-nav h3 {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 10px 10px;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
}

.category-nav a {
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    color: #525263;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    text-decoration: none;
    border-bottom: 1px solid #eee;
}

.category-nav a.has-toggle {
    padding-right: 50px;
}

.category-nav a:link,
.category-nav a:visited,
.category-nav a:hover,
.category-nav a:active {
    text-decoration: none;
}

.category-nav a .toggle {
    display: block;
    position: absolute;
    right: 10px;
    top: 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
}

.category-nav .active>.toggle .cb {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg)
}


.category-nav ul {}

.category-nav ul>li>a {
    padding-left: 12%;
}
.category-nav ul>li>a:before{
   position: absolute;
   left: 9%;
   top: 41%;
   content: " ";
   display: inline-block;
   width: 4px;
   height: 8px;
   background: url(../img/common/arrow_07.png) no-repeat center left;
   background-size: contain;
}


/****** Footer Setting ********/



#footer a {
    color: #fff;
    display: block;
}

.footer_logo_area .logo {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 0;
}

.footer_logo_area .copyright {
    font-size: 10px;
    font-size: 1rem;
    margin-top: 0;
}

.pagetop {
    display: none;
    text-align: right;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 2;
}

.pagetop a {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #626264;
    border-radius: 50%;
}

.pagetop a:hover {
    opacity: 0.8;
}

.pagetop a .cb-angle-down {
    fill: #fff;
    font-size: 25px;
    font-size: 2.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.5em;
    margin-top: -0.5em;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}



/****** Each BlockParts Setting ********/


/* Cart */

.cart {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0;
    z-index: 9999;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    transition: opacity .3s 0s, visibility 0s .3s;
}

.cart.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s;
}



/* Search */

.bt_search {
    border: 0;
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 44px;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
    z-index: 1;
}

.bt_search .cb-search {
    fill: #636378;
    font-size: 25px;
    font-size: 1.2rem;
}


#searchform {
   }

.search select {
    height: 50px;
    border: 1px solid #e0e0e0;
    font-size: 1.6rem;
    padding: 0.5em 1em;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    border: none;
}

.search select option {
    max-width: 190px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.search .input_search {
    height: 50px;
    padding: 0 50px 0 1em;
    /* border-bottom: 1px solid #e0e0e0; */
    position: relative;
    margin-left: 1%;
    background: #FFF;
    border-radius: 3px;
    padding: 0 1%;
}

.search input[type="search"] {
    height: 50px;
    width: 100%;
    font-size: 1.6rem;
    border: 0 none;
    padding: 0.5em 0;
    box-shadow: none;
    background: none;
    box-sizing: border-box;
}



/* Member area */

.member_link a {
    border-top: 1px solid #d8d7d3;
    color: #161616;
    display: block;
    font-size: 1.5rem;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    transform: translateZ(0px);
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    white-space: nowrap;
    width: 100%;
}



.member_link a .cb {
    font-size: 2rem;
    fill: #626265;
    margin-left: 10px;
    position: relative;
}

.member_link a:hover .cb {
    fill: #fff;
}

.cls-1 {
    fill: #72726f;
    fill-rule: evenodd;
}



/* News List */

.newslist {
    margin: 0 0 30px 0;
}

.newslist dl {
    margin: 0 0 10px 0;
    clear: both;
    border-bottom: 1px solid #e0e0e0;
}



.newslist dt {
    cursor: pointer;
    padding: 8px;
    position: relative;
    width: 100%;
}

.newslist {
    dt {
        display: block;
        .date {
            font-size: 1.5rem;
            color: #000;
            width: 30%;
            margin: 0 1% 0 0;
            float: left;
        }
        .news_title {
            font-size: 1.4rem;
            color: #242221;
            font-weight: bold;
            width: 56%;
            float: left;
        }
    }
    dd {
        font-size: 1.3rem;
        color: #242221;
    }
}

.newslist dt .angle-circle {
    position: absolute;
    right: 16px;
    top: 0px;
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #eee;
}

.newslist dt .cb {
    position: relative;
    top: 1px;
    left: 7px;
    fill: #fff;
    font-size: 11px;
    font-weight: normal;
}

.newslist dt:hover .news_title {
    text-decoration: underline;
}

.newslist dt.active .cb {
    position: relative;
    top: -1px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.newslist dt:hover .cb {
    opacity: 0.8;
}

.newslist dd {
    margin: 0 8px 8px;
    clear: both;
    display: none;
    a {
        color: #242221;
        display: block;
        background: #fedcd2;
        width: 40%;
        text-align: center;
        padding: 10px 0;
        border-radius: 5px;
        margin: 20px 0 20px 0;
    }
}



/* Item List Page------------------------------------------- */

.product_item {
    position: relative;
}

.product_item a {
    display: block;
}

.product_page .product_item a {
    padding-bottom: 16px;
    margin: 0 10px 10px 0;
}

.product_item a:hover {
    background: rgba(255, 255, 255, 0.3);
    text-decoration: none;
}

.product_item a:hover img {
    opacity: 0.8;
}

.item_photo {
    display: block;
    margin: 0 0 8px;
    text-align: center;
}

.item_photo img {
    width: 100%;
    border-radius: 10px;
}

.product_item .btn_circle {
    position: absolute;
    right: 8px;
    top: 8px;
}

.item_comment {
    margin: 0 0 8px;
}

.item_name {
    color: #242221;
    margin: 0;
}

.item_price {
    color: #df744a;
    font-weight: bold;
}

.item_price:before {
    content: ""
}

.item_price .small {
    font-size: 86%;
}

.special_price {
    color: #df744a;
}



/* Item Detail Page
------------------------------------------- */

dl.quantity dt {
    float: left;
    min-height: 40px;
    line-height: 40px;
    vertical-align: middle;
}

dl.quantity dd {
    padding-left: 3em;
}

.classcategory_list li {
    margin-top: 8px;
}

.classcategory_list li:first-child {
    margin-top: 0;
}

.btn_area .btn {
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 0 15px 0;
}

.btn_area .btn_icon .cb {
    display: inline-block;
    font-size: 30px;
    font-size: 3.0rem;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

#item_photo_area {
    padding-top: 8px;
    padding-bottom: 8px;
}

#item_photo_area img {
    text-align: center;
    border-radius: 10px;
}

#item_detail_area {
    margin: 0 0 20px 0;
}

#item_detail_area .small {
    font-size: 10px;
    font-size: 1rem;
    font-weight: normal !important;
}

#item_detail_area .item_name {
    font-size: 20px;
    font-size: 2rem;
    font-weight: normal;
    margin-bottom: 5px;
    background: none;
}

#item_detail_area .item_detail p {
    margin: 0;
}

.price01_default {
    text-decoration: line-through;
}

#item_detail_area .item_detail .sale_price {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
}

#item_detail_area .item_detail .announce {
    padding: 8px 0;
    font-weight: bold;
}

#item_detail_area .item_detail .point {
    padding: 8px 0;
}

#item_detail_area .item_detail .item_code {
    padding: 8px 0;
}

#item_detail_area .item_detail .relative_cat {
    padding: 8px 0;
}

#item_detail_area .item_detail .item_comment {
    margin-bottom: 8px;
}

.cart_area {
    padding-top: 8px;
}

.cart_area .classcategory_list li select {
    width: 100%;
}

.cart_area .quantity {
    margin: 8px 0 40px 0;
}

.cart_area .quantity dd input {
    max-width: 100px;
}

#item_detail .snsbtn_area {
    padding: 8px 0;
}



/* freearea */

#item_detail .freearea p {
    margin-top: 0;
}

#item_detail .freearea .heading01 {
    margin-bottom: 8px;
}



/* relative_cat */

.relative_cat>p {
    margin: 0;
    font-size: 1.4rem;
}

.relative_cat ol {
    list-style: none;
}

.relative_cat li {
    display: inline-block;
    color: #333;
}

.relative_cat li a {
    padding: 0 0.5em;
    display: inline-block;
    font-size: 1.4rem;
}

.relative_cat li:first-child a {
    padding-left: 0;
}

.relative_cat li:after {
    content: " > ";
    float: right;
}

.relative_cat li:last-child:after {
    content: "";
}



/* review_area */

#review_area {
    border-top: 1px solid #E8E8E8;
    padding-bottom: 0;
}

#review_area dl dt {
    padding: 16px 0;
    cursor: pointer;
}

#review_area dl dt:hover {
    color: #9797A0;
}

#review_area dl dt.active .cb {
    transform: rotate(180deg);
}

#review_area dl dt .cb {
    position: absolute;
    right: 16px;
    top: 17px;
}

#review_area dl dd {
    display: none;
    padding-bottom: 0;
}

#review_area dl dd .review_btn a {
    margin: 0 auto;
    padding: 16px;
    width: 100%;
}

#review_area .review_list {
    padding: 8px 0;
}

#review_area .review_list li {
    padding: 8px 0;
}

#review_area .review_list p {
    margin: 4px 0;
}

#review_area .review_list .review_date {
    font-weight: bold;
}

#review_area .review_list .recommend_level {
    margin-left: 16px;
}

#review_area .review_list .recommend_level span {
    color: #df744a;
}



/* keyword_area */

#keyword_area {
    border-top: 1px solid #E8E8E8;
}

#keyword_area .heading03 {
    padding: 8px 0 0;
}

#keyword_area ul li {
    display: inline-block;
    margin-right: 5px;
}

#keyword_area ul li a {
    display: inline-block;
    padding: 5px;
    font-size: 10px;
    background: #F5F7F8;
    border: 1px solid #D7DADD;
    color: #525263;
}

#keyword_area ul li a:hover {
    background: #EEF1F3;
}



/* recommend_area */

#recommend_area {
    border-top: 1px solid #E8E8E8;
}

#recommend_area .carousel {
    margin-left: -8px;
    margin-right: -8px;
}

#recommend_area .carousel .product_item {
    padding: 0 8px;
}

#recommend_area .carousel .product_item .btn_circle {
    right: 16px;
}

#recommend_area .slick-prev,
#recommend_area .slick-next {
    border: 0 none;
    background: 0 none;
    padding: 0;
    margin: 0;
    text-shadow: none;
    box-shadow: none;
    position: absolute;
    font-size: 18px;
    font-size: 1.8rem;
    width: 36px;
    height: 36px;
    line-height: 36px;
    vertical-align: middle;
    top: -2em;
}

#recommend_area .angle-circle {
    display: inline-block;
    background: #bfbfbf;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 3px;
}

#recommend_area .slick-prev .cb,
#recommend_area .slick-next .cb {
    fill: #fff;
    position: relative;
    top: -4px;
    right: -2px;
    font-size: 25px;
    font-size: 2.5rem;
}

#recommend_area .slick-prev .cb {
    left: -2px;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

#recommend_area .slick-prev:hover,
#recommend_area .slick-next:hover {
    opacity: 0.8;
}

#recommend_area .slick-disabled {
    opacity: 0.4;
    cursor: default;
}

#recommend_area .slick-disabled:hover {
    opacity: 0.4;
}

#recommend_area .slick-next {
    right: 0;
}

#recommend_area .slick-prev {
    right: 40px;
    left: auto;
}

#recommend_area .item_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



/* product_tag */

.product_tag_list {
    background: url(../img/common/bg_image_01.png);
    display: inline-block;
    font-size: 80%;
    color: #525263;
    padding: 3px 15px;
    border-radius: 5px;
}



/* Cart Page
------------------------------------------- */

#form_cart>.message:first-child {
    padding-top: 16px;
}

.total_box {
    padding: 8px 0;
}

.total_box dl {
    margin-left: 40%;
}

.total_box dl dt {
    float: left;
    width: 45%;
    white-space: nowrap;
    text-align: right;
    font-weight: bold;
}

.total_box dl dd {
    padding-left: 50%;
    text-align: right;
}

.total_box dl.total_price dd {
    font-weight: bold;
}

.total_box .btn_group {
    padding: 8px 0;
}

.total_box .btn_group p {
    margin: 8px 0;
}

.cart_item {
    border-top: 1px dotted #e0e0e0;
}

.cart_item .item_box>.td {
    border-bottom: 1px dotted #e0e0e0;
}

.cart_item .item_box .td {
    vertical-align: middle;
}

.cart_item .icon_edit {
    position: static;
    width: 8.3333333333%;
}

.cart_item .icon_edit a .cb {
    color: #626264;
    fill: #626264;
    font-size: 20px;
    font-size: 2rem;
}

.cart_item .icon_edit a:hover .cb {
    opacity: 0.8;
}

.cart_item .item_box a:hover img {
    opacity: 0.8;
}

.cart_item .item_box .table {
    width: 58.33333333%;
}

.cart_item .item_box .table .item_photo,
.cart_item .item_box .table .item_detail {
    display: table-cell;
    vertical-align: middle;
}

.cart_item .item_box .table .item_photo {
    min-width: 80px;
    padding-right: 8px;
}

.cart_item .item_box .table .item_detail {
    text-align: left;
    width: 100%;
}

.cart_item .item_box .table .item_name a {
    font-weight: bold;
    color: #525263;
}

.cart_item .item_box .table .item_name a:hover {
    color: #9797a1;
}

.cart_item .item_detail .item_subtotal {
    display: block;
}

.cart_item.table .tbody .tr div.item_subtotal {
    display: none;
}

.cart_item .item_quantity {
    width: 16.66666667%;
}

.cart_item .item_quantity::before {
    content: "数量：";
}

.cart_item .item_quantity ul {
    text-align: center;
    white-space: nowrap;
}

.cart_item .item_quantity ul li {
    display: inline-block;
    margin: 8px 4px;
}

.cart_item .item_quantity ul li a,
.cart_item .item_quantity ul li span {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
    border-radius: 50%;
}

.cart_item .item_quantity ul li a {
    border: 2px solid #c9c9c9;
}

.cart_item .item_quantity ul li span {
    border: 2px solid #e9e9e9;
}

.cart_item .item_quantity ul li a .cb {
    color: #525263;
    fill: #525263;
    margin-top: 6px;
    vertical-align: top;
}

.cart_item .item_quantity ul li span .cb {
    color: #babac1;
    fill: #babac1;
    margin-top: 6px;
    vertical-align: top;
}

.cart_item .item_quantity ul li a:hover {
    opacity: 0.8;
}

.column p {
    margin: 8px 0;
}

.column select {
    width: 100%;
}

.column textarea {
    width: 100%;
    height: 6em;
}

.column.is-edit>p {
    margin-right: 4em;
    min-height: 40px;
}

.column.is-edit .btn_edit {
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.column .form-inline input,
.column .form-inline select {
    margin-bottom: 8px;
}

.address_table {
    border-bottom: 1px dotted #e0e0e0;
}

.addr_box {
    position: relative;
}

.addr_box .icon_radio {
    vertical-align: middle;
    text-align: center;
    max-width: 32px;
}

div.table .tbody .addr_box .column {
    text-align: left;
}

.addr_box .column .address {
    margin: 0;
}

.addr_box .column.is-edit .address {
    margin: 0 4em 0 0;
    min-height: 40px;
}

.addr_box .column.is-edit .btn_edit {
    top: 10px;
}

.addr_box>.td {
    border-top: 1px dotted #e0e0e0;
}



/* payment_list */

.payment_list li {
    margin-bottom: 8px;
}

.payment_list li img {
    margin-top: 5px;
}

#shopping_confirm .payment_list .radio label {
    font-weight: normal;
}



/* MyPage
------------------------------------------- */

.local_nav ul {
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 1px 0 0 1px;
}

.local_nav ul:before,
.local_nav ul:after {
    content: " ";
    display: table
}

.local_nav ul:after {
    clear: both
}

.local_nav li {
    float: left;
    width: 50%;
    /* 項目が1行に3件の場合
    width: 33.33333333%;*/
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 0 1px 1px 0;
    text-align: center;
}

.local_nav li a {
    padding: 16px;
    font-weight: bold;
    color: #525263;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.local_nav li a:hover {
    background: #f5f7f8;
}

.local_nav li.active a {
    color: #df744a;
}

.order_detail dt {
    float: left;
    font-weight: bold;
}

.order_detail dd {
    padding-left: 7em;
}

.historylist_column {
    border-top: 1px solid #e0e0e0;
    margin: 0;
}

.historylist_column>div {
    padding: 0;
}

.historylist_column .order_date {
    margin: 8px 0;
}

.historylist_column .table.item_box {
    border-top: 1px dotted #e0e0e0;
}

.historylist_column .table.item_box .td {
    vertical-align: middle;
}

.historylist_column .order_date {
    font-size: 16px;
    font-size: 1.6rem;
}

.historylist_column .table.item_box .tbody .item_detail {
    padding: 8px 0;
    text-align: left;
}

.historylist_column .item_box .item_photo {
    padding: 8px 0;
    width: 20%;
    margin-bottom: 0;
    max-width: none;
    min-width: 100px;
}

.historylist_column .item_box .item_detail .item_name {
    font-weight: bold;
}

.historylist_column .item_box .item_detail .item_price {
    font-weight: normal;
    margin: 4px 0 8px;
}

.historylist_column+.pagination {
    margin-top: 0;
    border-top: 1px solid #e0e0e0;
}

.mail_list dl {
    margin-bottom: 8px;
    border-bottom: 1px dotted #e0e0e0;
}

.mail_list dl dt {
    margin-bottom: 4px;
}

.mail_list dl dd {
    margin-bottom: 8px;
}

.address_table .icon_edit {
    width: 8.33333%;
    vertical-align: middle;
}

.address_table .icon_edit a .cb {
    color: #626264;
    fill: #626264;
    font-size: 2rem;
}

.unsubscribe_box {
    text-align: center;
}

.unsubscribe_box .icon {
    font-size: 100px;
    font-size: 10rem;
    color: #E7E7E7;
}

.unsubscribe_box h3 {
    font-size: 16px;
    font-size: 1.6rem;
    margin: 0 0 16px;
}

#entry_birth select {
    margin: 0 8px;
}

#entry_birth select:first-child {
    margin-left: 0;
}

#entry_sex .radio {
    display: inline-block;
    margin-right: 10px;
}

#entry_sex .radio input[type="radio"] {
    margin-right: 10px;
}

.mypage .dl_table dt label {
    font-weight: bold;
}

#delivery_address_list div.is-edit {
    position: relative;
}



/* Error Page */

.message_box {
    text-align: center;
    padding-bottom: 50px;
}

.message_box .icon {
    font-size: 100px;
    font-size: 10rem;
    color: #e0e0e0;
}

.message_box h1 {
    font-size: 16px;
    font-size: 1.6rem;
    margin: 0 0 16px;
}

.message_box .btn_group {
    margin-top: 30px;
}

.error_page #contents {
    background: inherit;
}



/****** Effect Setting ********/


/* Overlay */

.overlay {
    /* shadow layer visible when navigation is active */
    position: fixed;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
}

.overlay.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s;
}



/* modal */

.modal-header .close {
    font-size: 22px;
    font-size: 2.2rem;
}

.modal-content {
    background-color: #F3F3F3;
}

.modal-body .form-control {
    width: 100%;
}

.modal-body .quantity {
    width: 50%;
    float: right;
    text-align: right;
    padding: 16px 0;
    font-size: 16px;
    font-size: 1.6rem;
}

.modal-body .quantity dd input {
    text-align: right;
}

.modal-body .classcategory_list li {
    margin-top: 16px;
}

.modal-body .classcategory_list li:first-child {
    margin-top: 0;
}

.modal-body .item_price {
    margin: 0;
    clear: both;
    text-align: right;
    font-size: 16px;
    font-size: 1.6rem;
}

.modal-body .item_price .small {
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 5px;
}

.modal-footer {
    padding: 16px 0;
    margin: 0 16px;
}

.modal-footer .btn_area {
    padding-bottom: 0;
}

.modal-footer .btn_area li:last-child {
    padding-left: 0;
}

.modal-footer .btn_area button {
    font-size: 16px;
    font-size: 1.6rem;
    height: 56px;
    line-height: 56px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}

.modal-footer .btn_area .btn_icon .cb {
    display: inline-block;
    font-size: 30px;
    font-size: 3.0rem;
    height: 54px;
    line-height: 54px;
    vertical-align: middle;
    position: relative;
    top: -2px;
}



/****** Each Page Setting ********/


/* Item List Page
------------------------------------------- */


/* Item Detail Page
------------------------------------------- */


/* Cart Page
------------------------------------------- */

.cart_page .dl_table {
    margin: 0 0 16px;
}

.cart_page .dl_table dt {
    font-weight: bold;
    margin-bottom: 5px;
}

.cart_page .form_terms_link {
    margin-bottom: 0;
}

#shopping_confirm .cart_item.table {
    border-top: 0 none;
}

#shopping_confirm .cart_item .item_box .table {
    width: 100%;
}

#shopping_confirm .total_box {
    margin-top: 16px;
    background: #F3F3F3;
    padding: 8px;
}

#shopping_confirm .total_box dl {
    margin-left: 0;
}

#shopping_confirm .total_box dl dt {
    font-weight: normal;
    text-align: left;
}

#shopping_confirm .total_box .total_amount {
    border-top: 1px dotted #e0e0e0;
    text-align: right;
}

#shopping_confirm .total_box .total_amount {
    margin-top: 8px;
}

#shopping_confirm .total_box .total_amount p {
    margin: 8px 0 0;
}

#shopping_confirm .total_box .total_amount p.total_price {
    font-weight: bold;
}

#shopping_confirm .total_box .total_amount p.total_price strong {
    font-size: 16px;
    font-size: 1.6rem;
    margin-left: 16px;
}

#shopping_confirm .total_box .total_amount p.total_price strong .small {
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 8px;
}

#shopping_confirm .total_box .total_amount p.total_point {
    margin: 4px 0 0;
}

#shopping_confirm .cart_item .item_box .table .item_name {
    margin-bottom: 0;
}

#shopping_confirm .cart_item .item_box .table .item_photo {
    max-width: 96px;
}

#shopping_confirm .cart_item .item_box .table .item_price {
    font-weight: normal;
    display: inline;
    margin-right: 16px;
}

#shopping_confirm .cart_item .item_box .table .item_subtotal {
    display: inline;
}

#shopping_confirm label {
    font-weight: bold;
}

#shopping_confirm .heading02 {
    margin-top: 24px;
    font-weight: bold;
    background: #EFEFEF;
    padding: 8px 12px;
}

#shopping_confirm .column {
    position: relative;
}

.heading02+.column .btn_edit {
    position: absolute;
    right: 0;
    top: 0;
}

#deliveradd_input .dl_table {
    border-top: 0 none;
}

.shipping_multiple_table {
    background-color: #f4f4f4;
}

.shipping_multiple_table .item_box>.td {
    border-bottom: none;
}

.shipping_multiple_table .item_box .item_photo {
    max-width: 200px;
    min-width: 200px;
    padding-right: 200px;
}

.shipping_item .quantity {
    width: 100px;
}

.use_point {
    text-align: center;
    margin: 16px 0;
}

.use_point input {
    text-align: right;
    width: 10%;
    min-width: 130px;
}

.use_point .text_accent {
    margin: 0 5px;
}

.multiple_column {
    padding-bottom: 16px;
}

.multiple_column .item_box {
    background: #F3F3F3;
}

.multiple_column .table.item_box .td {
    vertical-align: middle;
}

.multiple_column .table.item_box .tbody .item_detail {
    padding: 8px 0;
    text-align: left;
}

.multiple_column .item_box .item_photo {
    padding: 8px;
    width: 22%;
    margin-bottom: 0;
    max-width: none;
    min-width: 100px;
}

.multiple_column .item_box .item_detail .item_name {
    font-weight: bold;
}

.multiple_column .item_box .item_detail .item_price {
    font-weight: normal;
    margin: 4px 0 8px;
}

.multiple_column .form-group {
    margin: 8px 10px 0 0;
}

.multiple_column .item_quantity input {
    width: 100px;
}

.complete_message {
    text-align: center;
    p {
        width: 80%;
        margin: 0 auto 50px;
        text-align: left;
    }
}

.complete_message .heading01 {
    font-size: 24px;
    font-size: 2.4rem;
}



/* MyPage
------------------------------------------- */

.mypage .message {
    border-bottom: 1px dotted #e0e0e0;
    margin-bottom: 11px;
}

.mypage #item_list .product_item {
    padding-bottom: 8px;
}

.mypage #item_list .item_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2px;
}

.mypage #item_list .btn_circle span {
    position: relative;
    top: 3px;
}

.mypage .not_required {
    margin-top: 40px;
}

.mypage .title_hint {
    margin: 16px 0 0;
}

.mypage .title_hint+.form-group {
    padding-top: 4px;
}



/* Registration Page
------------------------------------------- */

.registration_page .not_required {
    margin-top: 16px;
}

.registration_page .title_hint {
    margin: 16px 0 0;
}

.registration_page .title_hint+.form-group {
    padding-top: 4px;
}

.registration_page .dl_table {
    margin: 0 0 16px;
}

.registration_page .dl_table dt {
    margin-bottom: 5px;
}

.registration_page .dl_table dt label {
    font-weight: bold;
}

.registration_page .form_terms_link {
    margin-bottom: 0;
}



/* Other Page
------------------------------------------- */


/* Login Page */

#login_box {
    padding-bottom: 32px;
}

#login_box .column {
    background: #F3F4F4;
    height: 100%;
    padding: 8px 8px 32px;
    margin-bottom: 16px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#login_box .column p {
    margin-top: 0;
    margin-bottom: 0;
}

#login_box .column .icon {
    text-align: center;
    font-size: 70px;
    color: #e7e7e7;
}

#login_box .column .icon svg {
    vertical-align: middle;
}

#login_box .column .form-group {
    padding: 8px 0 8px 10%;
}

#login_box .column input[type="text"],
#login_box .column input[type="email"],
#login_box .column input[type="password"] {
    width: 90%;
}

#login_box .column .form-group label {
    display: inline-block;
    padding: 5px 0;
}

#login_box .column .btn_area {
    padding: 8px 10%;
}

#login_box .column .btn_area ul {
    margin-top: 8px;
}

#login_box .column .message {
    padding: 8px 10%;
}



/* Forgot Page */

#forgot_page .column {
    background: #F3F4F4;
    padding: 16px;
    margin-bottom: 16px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#forgot_page .column .form-group {
    padding-top: 8px;
}

#forgot_page .form-group.input_name input[type="text"] {
    float: left;
    width: 49%;
}

#forgot_page .column p {
    margin: 0;
}

#forgot_page .column .btn_area {
    padding-top: 8px;
}

#forgot_page .column .btn_area p {
    margin: 0;
}

#forgot_page .column h2 {
    margin: 0 0 8px;
}



/* Contact Page */

#contact_contents {
    height: 100px;
}



/*  module
============================ */

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.is-padding {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.no-border-bottom {
    border-bottom: 0 none !important;
}

button.no-style {
    border: 0 none;
    background: none;
    padding: 0;
    margin: 0;
    text-shadow: none;
    box-shadow: none;
}

.text_accent {
    font-weight: bold;
}



/*  color setting
============================ */


/* text */

.text-default {
    color: #525263;
}

a.text-default:hover {
    color: #9797a1;
}

.text-primary {
    color: #df744a;
}

a.text-primary:hover {
    color: #e57d73;
}

.text-success {
    color: #18A689;
}

a.text-success:hover {
    color: #0D8969;
}

.text-info {
    color: #31708f;
}

a.text-info:hover {
    color: #245269;
}

.text-warning {
    color: #9A947E;
}

a.text-warning:hover {
    color: #AEA998;
}

.text-danger {
    color: #df744a;
}

a.text-danger:hover {
    color: #CF3F34;
}



/* bg */

.bg-default {
    color: #fff;
    background-color: #525263;
}

a.bg-default:hover {
    background-color: #9797a1;
}

.bg-primary {
    color: #fff;
    background-color: #df744a;
}

a.bg-primary:hover {
    background-color: #e57d73;
}

.bg-primary-border {
    color: #df744a;
    border: 2px solid #df744a;
    background: #fff;
}

a.bg-primary-border:hover {
    border: 2px solid #df744a;
    background: #FDF6F6;
}

.bg-success {
    background-color: #dff0d8;
}

a.bg-success:hover {
    background-color: #c1e2b3;
}

.bg-success-border {
    color: #5CB1B1;
    border: 2px solid #5CB1B1;
    background: #fff;
}

a.bg-success-border:hover {
    border: 2px solid #5CB1B1;
    background: #EEF7F7;
}

.bg-info {
    background-color: #d9edf7;
}

a.bg-info:hover {
    background-color: #afd9ee;
}

.bg-warning {
    background-color: #fcf8e3;
}

a.bg-warning:hover {
    background-color: #f7ecb5;
}

.bg-danger {
    color: #fff;
    background-color: #df744a;
    text-align: center;
}

a.bg-danger:hover {
    background-color: #FF8D8D;
}



/* btn */

.btn-default {
    color: #525263;
    background-color: #F5F7F8;
    border-radius: 5px;
    border-color: #e0e0e0;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open>.dropdown-toggle.btn-default {
    color: #525263;
    background-color: #EEF1F3;
    border-color: #72726f;
}

.btn-default:active,
.btn-default.active,
.open>.dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
    background-color: #F5F7F8;
    border-color: #D7DADD;
    color: #525263;
}

.btn-default .badge {
    color: #fff;
    background-color: #525263;
}

.btn-primary {
    color: #fff;
    background-color: #df744a;
    border-color: #df744a;
    border-radius: 5px;
    height: 53px;
    line-height: 53px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
    color: #fff;
    opacity: 0.8;
    background-color: #df744a;
    border-color: #df744a;
}

.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: #df744a;
    border-color: #df744a;
}

.btn-primary .badge {
    color: #fff;
    background-color: #df744a;
}

.btn-primary-border {
    color: #df744a;
    background-color: #fff;
    border-color: #df744a;
    border-width: 2px;
}

.btn-primary-border:hover,
.btn-primary-border:focus,
.btn-primary-border:active,
.btn-primary-border.active,
.open>.dropdown-toggle.btn-primary-border {
    color: #df744a;
    background-color: #FDF6F6;
    border-color: #df744a;
}

.btn-primary-border:active,
.btn-primary-border.active,
.open>.dropdown-toggle.btn-primary-border {
    background-image: none;
}

.btn-primary-border.disabled,
.btn-primary-border[disabled],
fieldset[disabled] .btn-primary-border,
.btn-primary-border.disabled:hover,
.btn-primary-border[disabled]:hover,
fieldset[disabled] .btn-primary-border:hover,
.btn-primary-border.disabled:focus,
.btn-primary-border[disabled]:focus,
fieldset[disabled] .btn-primary-border:focus,
.btn-primary-border.disabled:active,
.btn-primary-border[disabled]:active,
fieldset[disabled] .btn-primary-border:active,
.btn-primary-border.disabled.active,
.btn-primary-border[disabled].active,
fieldset[disabled] .btn-primary-border.active {
    background-color: #fff;
    border-color: #df744a;
}

.btn-success {
    color: #fff;
    background-color: #5CB1B1;
    border-color: #5CB1B1;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open>.dropdown-toggle.btn-success {
    color: #fff;
    background-color: #50A6A6;
    border-color: #50A6A6;
}

.btn-success:active,
.btn-success.active,
.open>.dropdown-toggle.btn-success {
    background-image: none;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
    background-color: #5CB1B1;
    border-color: #5CB1B1;
}

.btn-success .badge {
    color: #5CB1B1;
    background-color: #fff;
}

.btn-success-border {
    color: #5CB1B1;
    background-color: #fff;
    border-color: #5CB1B1;
}

.btn-success-border:hover,
.btn-success-border:focus,
.btn-success-border:active,
.btn-success-border.active,
.open>.dropdown-toggle.btn-primary-border {
    color: #5CB1B1;
    background-color: #EEF7F7;
    border-color: #5CB1B1;
    border-width: 2px;
}

.btn-success-border:active,
.btn-success-border.active,
.open>.dropdown-toggle.btn-success-border {
    background-image: none;
}

.btn-success-border.disabled,
.btn-success-border[disabled],
fieldset[disabled] .btn-success-border,
.btn-success-border.disabled:hover,
.btn-success-border[disabled]:hover,
fieldset[disabled] .btn-success-border:hover,
.btn-success-border.disabled:focus,
.btn-success-border[disabled]:focus,
fieldset[disabled] .btn-success-border:focus,
.btn-success-border.disabled:active,
.btn-success-border[disabled]:active,
fieldset[disabled] .btn-success-border:active,
.btn-success-border.disabled.active,
.btn-success-border[disabled].active,
fieldset[disabled] .btn-success-border.active {
    background-color: #fff;
    border-color: #5CB1B1;
}

.btn-info {
    color: #fff;
    background-color: #72726f;
    border-color: #72726f;
    border-radius: 5px;
    height: 43px;
    line-height: 43px;
    font-size: 1.6rem;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open>.dropdown-toggle.btn-info {
    color: #fff;
    background-color: #72726f;
    border-color: #72726f;
}

.btn-info:active,
.btn-info.active,
.open>.dropdown-toggle.btn-info {
    background-image: none;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
    background-color: #525263;
    border-color: #525263;
}

.btn-info .badge {
    color: #525263;
    background-color: #fff;
}

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open>.dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #E99133;
    border-color: #E99133;
}

.btn-warning:active,
.btn-warning.active,
.open>.dropdown-toggle.btn-warning {
    background-image: none;
}

.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff;
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open>.dropdown-toggle.btn-danger {
    color: #fff;
    background-color: #CF3F34;
    border-color: #CF3F34;
}

.btn-danger:active,
.btn-danger.active,
.open>.dropdown-toggle.btn-danger {
    background-image: none;
}

.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
    background-color: #d9534f;
    border-color: #d43f3a;
}

.btn-danger .badge {
    color: #d9534f;
    background-color: #fff;
}

.btn-link {
    font-weight: normal;
    color: #df744a;
    cursor: pointer;
    border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
    border-color: transparent;
}

.btn-link:hover,
.btn-link:focus {
    color: #2a6496;
    text-decoration: underline;
    background-color: transparent;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
    color: #777;
    text-decoration: none;
}



/* label */

.label-default {
    background-color: #777;
}

.label-default[href]:hover,
.label-default[href]:focus {
    background-color: #5e5e5e;
}

.label-primary {
    background-color: #df744a;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #e57d73;
}

.label-success {
    background-color: #18A689;
}

.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #0D8969;
}

.label-info {
    background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #3DA8CF;
}

.label-warning {
    background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color: #E99133;
}

.label-danger {
    background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #CF3F34;
}



/* panel */

.panel-primary {
    border-color: transparent;
}

.panel-primary>.panel-heading {
    color: #fff;
    background-color: #df744a;
    border-color: transparent;
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: transparent;
}

.panel-primary>.panel-heading .badge {
    color: #df744a;
    background-color: #fff;
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #df744a;
}

.panel-success {
    border-color: transparent;
}

.panel-success>.panel-heading {
    color: #18A689;
    background-color: #dff0d8;
    border-color: transparent;
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: transparent;
}

.panel-success>.panel-heading .badge {
    color: #dff0d8;
    background-color: #18A689;
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: transparent;
}

.panel-info {
    border-color: transparent;
}

.panel-info>.panel-heading {
    color: #fff;
    background-color: #42B3E5;
    border-color: transparent;
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: transparent;
}

.panel-info>.panel-heading .badge {
    color: #fff;
    background-color: #31708f;
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: transparent;
}

.panel-warning {
    border-color: transparent;
}

.panel-warning>.panel-heading {
    color: #fff;
    background-color: #fcf8e3;
    border-color: transparent;
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: transparent;
}

.panel-warning>.panel-heading .badge {
    color: #fff;
    background-color: #8a6d3b;
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: transparent;
}

.panel-danger {
    border-color: #ebccd1;
}

.panel-danger>.panel-heading {
    color: #fff;
    background-color: #df744a;
    border-color: transparent;
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: transparent;
}

.panel-danger>.panel-heading .badge {
    color: #fff;
    background-color: #df744a;
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: transparent;
}



/* alert */

.alert-success {
    color: #fff;
    background-color: #18a689;
    border-color: transparent;
}

.alert-success hr {
    border-color: transparent;
}

.alert-success .alert-link {
    color: #fff
}

.alert-info {
    color: #fff;
    background-color: #42B3E5;
    border-color: transparent;
}

.alert-info hr {
    border-top-color: #a6e1ec
}

.alert-info .alert-link {
    color: #fff
}

.alert-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: transparent;
}

.alert-warning hr {
    border-color: transparent;
}

.alert-warning .alert-link {
    color: #fff
}

.alert-danger {
    color: #fff;
    background-color: #df744a;
    border-color: transparent;
}

.alert-danger hr {
    border-color: transparent;
}

.alert-danger .alert-link {
    color: #fff
}



/*テンプレート追加*/

.main_visual {
    width: 100%;
    .item {
        width: 150%;
    }
    .item {
        width: 100%;
    }
    a {
        display: block;
        width: 100%;
        overflow: hidden;
        text-align: center;
        vertical-align: middle;
        font-size: 30px;
        font-size: 3rem;
        position: relative;
        height: 480px;
        p {
            position: absolute;
            top: 43%;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            min-width: 70%;
            height: auto;
            font-size: 80%;
            width: 90%;
        }
    }
    .main_visual_01 {
        background: url(../img/top/mv01.jpg) no-repeat center;
        background-size: cover;
        p {
            color: #fff;
        }
    }
    .main_visual_02 {
        background: url(../img/top/mv02.jpg) no-repeat center;
        background-size: cover;
        p {
            color: #fff;
        }
    }
    .main_visual_03 {
        background: url(../img/top/mv03.jpg) no-repeat right center;
        background-size: cover;
        p {
            color: #fff;
        }
    }
    img {
        margin: 0;
        width: 100%;
    }
}



.bg_gray {
    background: url(../img/common/bg_gray.png);
    padding: 20px 0 40px 0;
    margin: 0 0 20px 0;
}

#new_arrival .new_arrival_slide {
    width: 100%;
    margin: 0 auto 50px;
}

#new_arrival .new_arrival_slide .item {
    margin: 5px;
}

#new_arrival .pickup_item dl {
    font-size: 1.6rem;
    text-align: center;
}

.new_arrival_item_comment {
    display: none;
}

#new_arrival .new_arrival_slide img {
    border-radius: 10px;
}

#ranking #item_list {
    background: #fff;
    .top3 {
        dt img {
               width: 100%;
        }
    }

    .item_photo img {
        border-radius: 10px;
    }
    dl {
        margin: 0 0 10px 0;
        text-align: center;
    }
    dt {
        width: 30%;
        margin: 0 auto 10px;
    }
    .item_name,
    .item_price{
width: 100%;
margin: 0 ;
    }
}
#news_area a#news_readmore {
    width: 60%;
    margin: 30px auto 60px;
    display: block;
    background: #fedcd2;
    border-radius: 10px;
    color: #242221;
    text-align: center;
    padding: 10px 0;
    position: relative;
}

#news_area a#news_readmore:after {
    position: absolute;
    right: 7%;
    margin: 1% 0 0 0;
    content: " ";
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url(../img/common/arrow_06.png) no-repeat center;
    background-size: contain;
}


#guide {
    ul {
        margin: 0 auto;
        text-align: center;
        width: 98%;
        li {
            width: 48%;
            float: left;
            @media #{$not_sp} {
                width: 25%;
            }
        }
        a {
            padding: 10% 0 70%;
            display: block;
        }

        li:nth-child(1) a {
            background: url(../img/common/btn_guide_01.svg) no-repeat center 80%;
            background-size: 50%;
            border-right: solid 1px #e0e0e0;
            border-bottom: solid 1px #e0e0e0;
        }
        li:nth-child(2) a {
            background: url(../img/common/btn_guide_02.svg) no-repeat center 80%;
            background-size: 50%;
            border-bottom: solid 1px #e0e0e0;
        }
        li:nth-child(3) a {
            background: url(../img/common/btn_guide_03.svg) no-repeat center 80%;
            background-size: 50%;
            border-right: solid 1px #e0e0e0;
        }
        li:nth-child(4) a {
            background: url(../img/common/btn_guide_04.svg) no-repeat center 80%;
            background-size: 50%;
        }
        @media #{$not_sp} {
            li:nth-child(1) a {
                background-size: 40%;
                border-bottom: none;
            }
            li:nth-child(2) a {
                background-size: 40%;
                border-bottom: none;
                border-right: solid 1px #e0e0e0;
            }
            li:nth-child(3) a {
                background-size: 40%;
                border-bottom: none;
            }
            li:nth-child(4) a {
                background-size: 40%;
            }
            a {
                padding: 3% 0 46%;
            }
        }
    }
}


#page-top {
    position: fixed;
    right: 3%;
    margin: 0;
    z-index: 9999;
    a {
        background: #fedcd2;
        border-radius: 10px;
        padding: 25% 30% 20%;
        display: block;
        width: 50px;
        text-align: center;
        height: 50px;
    }
}

.cart_detail {
    min-width: 256px;
    background: url(../img/common/bg_image_01.png);
    margin: 10px;
}

.top_title {
 background: url(../img/common/tit_line_01.png) repeat-x bottom;
 text-align: center;
 margin: 0 auto 27px;
 padding: 5px 0 10px 0;
 width: 97%;
}

.footer_bottom {
    .copyright {
        color: #fff;
        margin: 0;
        text-align: center;
        padding: 15px 0;
    }
}
.pick_up_contents{
    li{    padding: 2%;}
}




/* main */

.theme_side_left #main {
    @media #{$pc} {
        margin: 0 0 70px 0;
        float: left;
        width: 70%;
    }
    @media #{$not_pc} {
        margin: 0 auto 70px;
        width: 90%;
    }
}



/******************************
 Media Query*******************************/

 @media #{$sp} {
    .drawer-open #contents,
    .drawer-open #header,
    .drawer-open #footer,
    .drawer-open .pagetop {
        -webkit-transform: translateX(-82%);
        -moz-transform: translateX(-82%);
        -ms-transform: translateX(-82%);
        -o-transform: translateX(-82%);
        transform: translateX(-82%);
    }
    /* Overlay */
    .overlay.is-visible {
        -webkit-transform: translateX(-82%);
        -moz-transform: translateX(-82%);
        -ms-transform: translateX(-82%);
        -o-transform: translateX(-82%);
        transform: translateX(-82%);
    }
    .overlay.is-visible.cart-is-visible {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
    /****** drawer Setting ********/
    .drawer {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 82%;
        background: #F6F6F6;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 1;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }
    
}

@media #{$not_pc} {
    /* --------- common ---------- */
    /*sp_inner*/
    #item_list,
    #news_area,
    #guide,
    .pick_up_contents,
    .footer_top,
    #ranking {
        padding: 0 1%;
        clear: both;
    }

    .sp {
        display: block !important;
    }
    .pc {
        display: none !important;
    }
    span.sp {
        display: inline !important;
    }
    /****** Side Setting ********/
    .drawer {
        height: 0;
        visibility: hidden;
        -webkit-transition: visibility 0s 0.3s;
        -moz-transition: visibility 0s 0.3s;
        transition: visibility 0s 0.3s;
    }
    .drawer-open .drawer {
        height: 100%;
        visibility: visible;
        transition: visibility 0s 0s;
        background: url(../img/common/bg_image_01.png);
        border-left: 1px solid #e0e0e0;
        z-index: 100;
    }
    /*
    #acMenu dt {
        display: block;
        cursor: pointer;
        background: #fff;
        h3 {
            font-size: 1.6rem;
            padding: 10px 30px;
            margin: 0;
            border-bottom: dotted 1px #e0e0e0;
        }
    }
    */
    #acMenu {
        dt {
            display: block;
            cursor: pointer;
            background: url(../img/common/arrow_04.png) 95% center no-repeat #fff;
            background-size: 10px;
            padding-right: 15px;
            h3 {
                font-size: 1.6rem;
                padding: 10px 20px;
                margin: 0;
                border-bottom: dotted 1px #e0e0e0;
            }
        }

        dt.active {
            background: url(../img/common/arrow_03.png) 95% center no-repeat #fff;
            background-size: 10px;
        }
        dd {
            display: none;
        }
    }
    .category-nav a {
        will-change: transform, opacity;
        padding: 0 0 0 20px;
    }
    /****** Effect Setting ********/
    /* My page address */
    #delivery_address_list div.is-edit p.btn_edit {
        top: 30%;
    }
    #search select {
        width: 100%;
    }
    #header {
        position: relative;
        transition: transform 0.3s;
        padding: 0 2%;
        margin: 0 0 20px 0;
    }
    .bt_search .cb-search {

        font-size: 2rem;
    }
    #cart_area p.cart-trigger {
        position: absolute;
        right: 60px;
        top: 18px;
    }
    .member_link {
        a .cb {
            float: left;
            margin: 15px 10px;
            .sp {
                float: left;
            }
        }
    }
    
    .item_name,
    .item_price {
        font-size: 1.6rem;
    }
    .new_arrival .slick-dots {
        position: absolute;
        bottom: 10px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
    }



    #item_photo_area img {
        text-align: center;
        border-radius: 10px;
        display: none;
    }
    .drawer_link {
        li {
            padding: 0 2%;
            a {
                display: block;
                padding: 10px 20px;
                border-bottom: 1px solid #e0e0e0;
                font-weight: bold;
            }
        }
    }
    #footer {
     background: rgba(149, 184, 176, 0.85);
     padding: 20px 0 0 0;
     margin: 0;
     .footer_logo {
       
            margin: 0 auto 30px;
            text-align: center;
    }
    .footer_logo_02 {
        text-align: center;
        font-size: 2rem;
    }

    .footer_link {

        li {
            float: left;
            width: 40%;
            text-align: left;
            font-size: 1.2rem;
            a {
                white-space: nowrap;
            }
        }
        li:nth-child(2n) {
            border-left: solid 1px #8b8b89;
            padding: 0 0 0 10px;
        }
    }
}
.sns {
 margin: 0 auto;
 width: 50%;
 @media #{$tab} {
    width: 30%;
 }
 ul {
    text-align: center;
    li {
        float: left;
        width: 33%;
        margin: 0;
        svg {
            width: 80%;
            height: 50px;
        }
    }
    li a {
        color: #c55938;
        img {
            width: 70%;
        }
    }
}
}
}



@media #{$pc} {
    /* --------- common ---------- */
    /*pc_inner*/
    #category_pc,
    .pc_inn {
        width: 1020px;
        margin: 0 auto;
    }

    /*↓↓↓　トップページのみの設定（メインビジュアル）　↓↓↓*/
    #page_homepage {
        width: 100%;
        padding: 0;
        background: none;
        #contents {
            top: 0;
            padding: 0;
        }
        #contents {
            h3 {
                background: url(../img/common/tit_line_01.png) repeat-x bottom;
                text-align: center;
                margin: 0 0 27px 0;
                padding: 5px 0 15px 0;
                svg.top_tit {
                    height: 28px;
                }
            }
        }
    }

    /* ↓↓↓↓　PC　ヘッダー　下層ページ　↓↓↓↓ */
    .nav_02,
    .header {
        background: rgba(149, 184, 176, 0.85);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        .inner {
            padding: 10px 0;
        }
        .header_logo_area {
            float: left;
            width: 23%;
            margin: 0 2% 0 0;
        }
        .header_nav {
            float: right;
            width: 72%;
            padding: 50px 0 0 0;
            text-align: center;
            ul {
                float: left;
                left: 50%;
                position: relative;
                width: 100%;
                li {
                    float: left;
                    left: -50%;
                    position: relative;
                    width: 20%;
                    border-left: dotted 1px #fff;
                }
                li:last-child {
                    border-right: dotted 1px #fff;
                }
                a {
                    display: block;
                    color: #fff;
                    @media #{$not_pc} {
                        font-size: 2vw;
                    }
                }
            }
        }
        #icon_right_box {
            width: 500px;
        }
        #search {
            padding: 0 !important;
            height: 0;
            width: 70%;
            float: left;
        }
    }

    /*↓↓↓　ヘッダーのトップページのみ　↓↓↓*/
    #page_homepage {
        .is-top {
            background: rgba(0, 0, 0, 0);
            position: absolute;
            #header .copy {
                font-size: 1.2rem;
                padding: 10px 0 0 0;
            }
            #icon_right_box {
                width: 350px;
                padding: 5px 0 0 0;
            }
            #search {
                padding: 0 !important;
                height: 0;
                width: 100%;
                float: none;
            }
            .header_logo {
                margin: 0;
                padding: 0;
                width: 100%;
            }

            .header_logo_area {
                float: none;
                width: 100%;
                margin: 0 1% 10px 0;
                text-align: center;
            }
            .header_nav {
                margin: 0;
                float: none;
                width: 100%;
                padding: 0;
                text-align: center;
                clear: both;
                ul {
                    float: left;
                    left: 50%;
                    position: relative;
                    width: 100%;
                }
                li {
                    float: left;
                    left: -50%;
                    position: relative;
                    width: 20%;
                }
                span {
                    font-size: 1.1rem;
                    display: block;
                    color: #fff;
                }
            }
        }
        .nav_02 {
            background: rgba(149, 184, 176, 0.85);
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            -moz-transition: all 2s;
            -o-transition: all 2s;
            -webkit-transition: all 2s;
            transition: all 2s;
            #icon_right_box {
                width: 500px;
            }
            #search {
                width: 70%;
                float: left;
            }
            .header_logo_area {
                float: left;
                width: 25%;
                margin: 0;
                text-align: left;
            }
            .header_nav {
                float: right;
                width: 72%;
                padding: 50px 0 0 0;
                clear: initial;
            }
        }
    }
    #news_area,
    .pick_up_contents ul li,
    #category,
    #new_arrival,
    #ranking,
    #guide,
    #footer,
    #category_pc,
    .right_icon,
    .header_nav,
    #item_list,
    .pagination ul li {
        a {
            display: block;
            vertical-align: middle;
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px transparent;
            position: relative;
            transition-duration: 0.3s;
            transition-property: transform;
            border: none;
        }
        a:hover {
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
        }
    }
    #contents {
        background: #fff;
        padding: 0;
        margin: 0;
        padding: 100px 0 50px 0;
    }

    #page-top {
        position: fixed;
        bottom: 20px;
        right: 100px;
        margin: 0;
        z-index: 9999;
        border-radius: 10px;
    }
    .container-fluid {
        padding-left: 6px;
        padding-right: 6px;
    }
    .row {
        margin-left: -6px;
        margin-right: -6px;
        padding-bottom: 6px;
    }
    .col-xs-1,
    .col-sm-1,
    .col-md-1,
    .col-lg-1,
    .col-xs-2,
    .col-sm-2,
    .col-md-2,
    .col-lg-2,
    .col-xs-3,
    .col-sm-3,
    .col-md-3,
    .col-lg-3,
    .col-xs-4,
    .col-sm-4,
    .col-md-4,
    .col-lg-4,
    .col-xs-5,
    .col-sm-5,
    .col-md-5,
    .col-lg-5,
    .col-xs-6,
    .col-sm-6,
    .col-md-6,
    .col-lg-6,
    .col-xs-7,
    .col-sm-7,
    .col-md-7,
    .col-lg-7,
    .col-xs-8,
    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xs-9,
    .col-sm-9,
    .col-md-9,
    .col-lg-9,
    .col-xs-10,
    .col-sm-10,
    .col-md-10,
    .col-lg-10,
    .col-xs-11,
    .col-sm-11,
    .col-md-11,
    .col-lg-11,
    .col-xs-12,
    .col-sm-12,
    .col-md-12,
    .col-lg-12 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .sp {
        display: none !important;
    }
    .pc {
        display: block !important;
    }
    span.pc {
        display: inline !important;
    }
    /* --------------------------------
        Parts
    -------------------------------- */
    /* heading */
    h2 {
        font-size: 24px;
        font-size: 2.4rem;
    }
    .page-heading {
        font-size: 32px;
        font-size: 3.2rem;
        border-bottom-style: solid;
        border-top: 0 none;
        padding: 16px 0 12px;
        margin: 0 16px 48px;
    }
    .heading01,
    .heading03 {
        font-size: 24px;
        font-size: 2.4rem;
        font-weight: bold;
    }
    .heading02 {
        font-size: 24px;
        font-size: 2.4rem;
        font-weight: bold;
        background: #EFEFEF;
        padding: 8px 12px;
    }
    .heading05 {
        font-size: 16px;
        font-size: 1.6rem;
    }
    /* btn */
    .btn-lg {
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333
    }
    .btn-sm {
        padding: 8px 10px;
        font-size: 14px;
        line-height: 1.5
    }
    .btn-xs {
        padding: 5px 8px;
        font-size: 12px;
        line-height: 1.5
    }
    a.btn.btn-info.btn-block.btn-lg {
        padding: 9px 0;
    }
    /* badge */
    .badge {
        font-size: 12px;
        font-size: 1.2rem;
    }
    /* tableStyle */
    div.table .thead {
        display: table-header-group;
    }
    div.table ol {
        display: table-row;
        margin: 0;
    }
    div.table ol li {
        display: table-cell;
        padding: 16px;
        text-align: center;
        background: #F4F3F0;
        overflow-x: hidden;
        font-weight: bold;
    }
    div.table .tbody .tr>.td {
        padding: 16px;
    }
    /* tableish */
    .tableish {
        display: table;
        width: 100%;
    }
    .tableish .thead {
        display: table-header-group;
    }
    .tableish .tbody {
        display: table-row-group;
    }
    .tableish .tr {
        display: table-row;
    }
    .tableish .th,
    .tableish .td {
        display: table-cell;
        vertical-align: top;
    }
    /* dl-table */
    .dl_table {
        display: table;
        width: 100%;
    }
    .dl_table dl {
        display: table-row;
        border: 0 none;
    }
    .dl_table dt,
    .dl_table dd {
        display: table-cell;
        border-bottom: 1px dotted #e0e0e0;
        padding: 16px 0;
    }
    .dl_table dt {
        width: 25%;
    }
    .dl_table dd {
        padding: 16px;
    }
    .dl_table dd .form-group {
        padding-top: 16px;
    }
    .dl_table .zip-search {
        margin-top: 16px;
    }
    /* pagination */
    .pagination {
        width: 100%;
    }
    /* topicpath */
    #topicpath {
        padding: 0;
        border: 0 none;
        a {
            font-weight: normal;
            font-size: 1.8rem;
        }
    }
    #topicpath ol {
        padding-top: 0.7em;
        padding-bottom: 0.7em;
    }
    #topicpath li {
        font-size: 16px;
        font-size: 1.6rem;
    }
    /* intro */
    .intro {
        margin: 0;
        padding-top: 0.5em;
        padding-bottom: 28px;
    }
    /* message */
    .heading04+.message {
        padding: 0;
    }
    .message .errormsg {
        padding: 16px;
    }
    /* flowline */
    .flowline {
        margin: 0;
        border-bottom: 0 none;
    }
    .flowline ul::before {
        top: 20px;
    }
    .flowline.step3 ul {
        max-width: 450px;
    }
    .flowline.step4 ul {
        max-width: 550px;
    }
    .flowline ul li {
        font-size: 14px;
        font-size: 1.4rem;
    }
    .flowline li .flow_number {
        line-height: 42px;
        width: 42px;
        height: 42px;
        font-size: 20px;
        font-size: 2rem;
    }
    /* .cart .item_box */
    .cart_detail {
        padding: 0 16px;
    }
    .cart .item_box>div,
    .cart .item_box>dl {
        padding: 16px 0;
        .item_name{
            font-size: 2rem;
            color: #242221;
        }
    }
    .cart .item_box .item_photo {
        padding: 16px 8px 16px 0;
    }
    .cart .item_box .icon_edit {
        right: -7px;
        top: 10px;
    }
    .cart .btn_area {
        padding: 0;
        clear: both;
    }
    /* error_box */
    .error_box {
        margin-bottom: 48px;
    }
    /****** Layout ********/
    #contents {
        min-height: 100%;
    }
    #side_left {
        width: 22%;
        float: left;
        padding: 0 3%
    }
    #side_right {
        float: right;
        width: 25%;
        padding-left: 16px;
    }

    /* -- main_left (side_right only) -- */
    /* main */
    .theme_side_right #main {
        width: 75%;
        float: left;
    }
    /* -- main_right (side_left only) -- */
    /* -- main_only -- */
    .main_only {
        padding: 0 3%;
    }
    /* main */
    .theme_main_only #main {
        width: 100%;
    }
    /* -- main_left_right (side_left & side_right) -- */
    /* main */
    .theme_side_both #main {
        width: 50%;
        float: left;
    }
    /* category_nav */
    #category {
        h3 {
            font-size: 1.6rem;
        }
    }
    /****** Header Setting ********/
    #header .copy {
        font-size: 1.2rem;
        padding: 0;
        vertical-align: middle;
        margin: 0 0 5px 0;
    }
    .header_logo_area .header_logo {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    /* header-buttons */
    .header-buttons {
        top: 18px;
        right: 4em;
    }
    /* cart_area */
    #header .right_icon {
        width: 70%;
        float: right;
        padding: 5px 0 0 0;
    }
    #header #cart_area {
        float: right;
        width: auto;
        position: inherit;
        .cart {
            top: 70px;
        }
        .cart_detail {
            position: absolute;
            right: 2%;
            top: 0;
            width: 100%;
            padding: 10px 4%;
            background: #f5f7f8;
            display: block;
        }
        .open_cart {
            width: 470px;
            position: absolute;
            top: 0;
            right: 0;
            background: aliceblue;
            padding: 10px 30px;
            border: solid 1px #e0e0e0;
        }
    }
    #header #cart_area p.cart-trigger a {
        position: relative;
        float: left;
    }
    p.cart-trigger a {
        display: inline-block;
        width: auto;
        height: 25px;
        vertical-align: middle;
        text-align: center;
        outline: none;
        margin: 3px 0 0 0;
    }
    #cart_area p.cart-trigger {}
    #header #cart_area p.cart-trigger .cb-shopping-cart {
        position: relative;
        left: 0px;
        font-size: 20px;
    }
    #header #cart_area p.cart-trigger .badge {
        position: relative;
        left: 0;
        top: 0;
    }
    p.cart-trigger .cb-close {
        position: absolute;
        left: 0;
        top: 0;
    }
    #header #cart_area .cart_price {
        float: right;
        margin: 0 0 10px 0;
        padding: 20px 0 0 0;
        width: 100%;
    }
    #cart_area .tex {
        font-size: 1.3rem;
        position: absolute;
        top: 4px;
        left: 38px;
    }
    /* search_area */
    #icon_right_box {

        z-index: 100;
        position: fixed;
        right: 1%;
        top: 3px;
    }
    #search {
        padding: 0!important;
        height: 0;
    }
    #search select {
        width: 50%;
    }
    #header .search .input_search {
        float: right;
        width: 47%;
        margin: 0;
    }
    .search select option {
        max-width: 246px;
        color: #000;
    }
    .search select {
        height: 25px;
        border: 1px solid #fff;
        font-size: 13px;
        font-size: 1.3rem;
        border-radius: 0;
        box-shadow: none;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0);
        padding: 0;
        color: #fff;
    }
    .input_search {
        color: #fff;
    }
    .search .input_search {
        height: 25px;
        height: 25px;
        margin-left: 1%;
        background: rgba(255, 255, 255, 0);
        border-radius: 3px;
        padding: 0 1%;
        border: solid 1px #fff;
    }
    .search input[type="search"] {
        height: 25px;
        width: 100%;
        font-size: 16px;
        font-size: 16px;
        font-size: 1.6rem;
        border: 0 none;
        box-shadow: none;
        color: #fff;
        background: none;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0);
    }

    .bt_search {
        border: 0;
        background: none;
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        width: 25px;
        height: 20px;
        overflow: hidden;
        white-space: nowrap;
        z-index: 1;
    }
    /****** Side Setting ********/
    #btn_menu {
        display: none;
    }
    .drawer {
        position: static;
        height: auto;
        width: auto;
        margin: 0 auto;
        overflow: visible;
        background: transparent;
        text-align: center;
    }

    /*  Category navigation
    --------------------------- */
    /* category-nav */
    #main_top {}
    #main_middle {}
    .category-nav>li {
        text-align: left;
        margin: 0 1em;
        position: relative;
    }
    .category-nav ul {
        position: static;
        height: auto;
        width: auto;
        overflow: visible;
        z-index: 3;
    }
    .category-nav>li>a {
        position: relative;
        display: inline-block;
        color: #2e3233;
        overflow: visible;
        -webkit-transition: color 0.3s, box-shadow 0.3s;
        -moz-transition: color 0.3s, box-shadow 0.3s;
        transition: color 0.3s, box-shadow 0.3s;
    }
    #category li a {
        font-size: 1.5rem;
        overflow: hidden;
        border-bottom: solid 1px #e0e0e0;

    }
    /* ----- category_nav in header ---- */
    #header #category {
        padding: 20px 0 0;
    }
    /* category-nav */
    #header .category-nav {
        border-top: 1px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9;
        text-align: center;
    }
    #header .category-nav .toggle {
        display: none;
    }
    #header .category-nav>li {
        margin: 0;
    }
    #header .category-nav li ul {
        display: block;
    }
    #header .category-nav li {
        position: relative;
        display: inline-block;
    }
    #header .category-nav>li>a {
        padding: 0 1em;
        height: 55px;
        line-height: 55px;
        border-bottom: none;
    }
    #header .category-nav li a {
        display: block;
    }
    #header .category-nav li ul {
        position: absolute;
        z-index: 100;
        top: 100%;
        left: 0;
        width: 200px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    }
    #header .category-nav li ul li {
        float: left;
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .2s;
    }
    #header .category-nav li ul li a {
        text-align: left;
    }
    #header .category-nav>li:hover>a {}
    #header .category-nav>li:hover li:hover>a {
        background: rgba(204, 204, 204, 0.1)
    }
    #header .category-nav>li:hover>ul>li {
        overflow: visible;
        height: 50px;
    }
    #header .category-nav li ul li ul {
        top: 0;
        left: 100%;
    }
    #header .category-nav li:last-child ul li ul {
        left: 100%;
        width: 100%;
    }
    #header .category-nav li ul li ul:before {
        position: absolute;
        content: ">";
        top: 13px;
        left: -15px;
        width: 0;
        height: 0;
    }
    #header .category-nav li:last-child ul li ul:before {
        position: absolute;
        content: ">";
        top: 13px;
        left: -15px;
        width: 0;
        height: 0;
    }
    #header .category-nav li ul li:hover>ul>li {
        overflow: visible;
        height: 50px;
    }
    #header .category-nav li ul li ul li a {}
    #header .category-nav li:hover ul li ul li a:hover {
        background: rgba(204, 204, 204, 0.1)
    }
    #header .category-nav ul>li>a,
    #header .category-nav ul>li>ul>li>a,
    #header .category-nav ul>li>ul>li>ul>li a {
        padding-left: 10px;
    }
    /* ----- category_nav in side ---- */
    /* category-nav */
    #category {
        background: #fff;
        border-radius: 10px;
        padding: 4% 6%;
        h3 {
            background: url(../img/common/tit_line_01.png) repeat-x bottom;
            text-align: center;
            margin: 0 0 27px 0;
            padding: 5px 0 5px 0;
            text-align: left;
            padding: 0 0 10px 0;
        }
    }
    .side .category-nav>li {
        display: block;
        margin: 0;
    }
    .side .category-nav li>a {
        width: 100%;
    }
    .side .category-nav>ul ul {
        width: 100%;
    }
    .side .category-nav>ul {
        width: 100%;
    }
    .side .category-nav>ul>li {
        width: 100%;
    }
    .side .category-nav>ul>li a {
        padding-left: 10px;

    }
    /*フリーエリア　メインビジュアル*/
    .main_visual {
        min-width: 100%;
        height: auto;
        item {
            width: 100%;
        }
        a {
            display: block;
            width: 100%;
            height: 880px;
            overflow: hidden;
            text-align: center;
            vertical-align: middle;
            font-size: 4.3rem;
            position: relative;
            p {
                position: absolute;
                top: 43%;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                min-width: 70%;
                height: auto;
                font-size: 3.4rem;
            }
        }
    }
    #category_pc {
        margin-bottom: 70px;
        padding: 40px 0 0 0;
        text-align: center;
        ul {
            padding: 0 2% 0 0;
        }

        li {
            float: left;
            width: 15.6%;
            margin: 0 1% 25px 0;
            dt {
                margin: 0 0 5px 0;
            }
        }
        a {
            display: block
        }
        img {
            border-radius: 10px;
        }
    }
    /*新商品*/
    #new_arrival {
        img {
            border-radius: 0;
        }
    }

    /*ランキング*/
    #ranking {
        margin-bottom: 70px;

        .top10 {
            clear: both;
        }
    }
    /* newslist */
    #news_area {
        margin-bottom: 70px;
        background: #fff;
        border-radius: 10px;
        padding: 1%;
    }
    .newslist {
        margin: 0;
        dl {
            dt .date {
                float: left;
                width: 8%;
                margin: 0 1% 0 0;
                font-size: 1.5rem;
            }
            dt .news_title {
                float: left;
                width: 70%;
                text-align: left;
                padding: 0;
                font-size: 1.5rem;
            }
            dd {
                width: 85%;
                font-size: 1.4rem;
                margin: 0 0 0 10%;
                a {
                    width: 15%;
                }
            }
        }
    }
    #news_area a#news_readmore {
        width: 30%;
    }

    /* Item List Page
    ------------------------------------------- */
    .product_page .product_item {
        padding-bottom: 24px;
    }
    .item_photo {
        margin: 0 0 15px;
    }
    .item_comment {
        margin: 0 0 15px;
    }
    /* Item Detail Page
    ------------------------------------------- */
    #detail_wrap {
        margin: 0 0 30px 0;
    }
    #item_photo_area {
        width: 48%;
        float: left;
        margin: 0 2% 0 0;
    }
    #item_detail_area {
        margin: 0 0 20px 0;
        float: right;
        width: 48%;
    }
    #item_detail_area .small {
        font-size: 12px;
        font-size: 1.2rem;
    }
    #item_detail_area .item_name {
        font-size: 32px;
        font-size: 3.2rem;
        margin-bottom: 10px;
        text-align: left;
    }
    #item_detail_area .item_detail .sale_price {
        font-size: 28px;
        font-size: 2.8rem;
    }
    #item_detail_area .item_detail .sale_price .small {
        font-size: 16px;
        font-size: 1.6rem;
    }
    #item_detail_area .item_detail .announce {
        padding: 14px 0;
    }
    #item_detail_area .item_detail .point {
        padding: 14px 0;
        border-top: 1px dotted #e0e0e0;
    }
    #item_detail_area .item_detail .item_code {
        margin-top: 14px;
        margin-bottom: 14px;
    }
    #item_detail_area .item_detail .relative_cat {
        padding: 14px 0;
    }
    #item_detail_area .item_detail .item_comment {
        margin-top: 14px;
        margin-bottom: 14px;
        clear: both;
    }
    .cart_area {
        padding-top: 14px;
        background: url(../img/common/bg_image_01.png);
        padding: 20px;
    }

    .cart_area .classcategory_list li {
        margin-top: 16px;
    }
    .cart_area .classcategory_list li:first-child {
        margin-top: 8px;
    }
    .cart_area .classcategory_list li select {
        width: 100%;
        padding: 10px 0;
    }
    .cart_area .quantity {
        margin: 14px 0;
    }
    #item_detail .snsbtn_area {
        padding: 14px 0;
    }
    /* freearea */
    #item_detail .freearea p {
        margin-bottom: 40px;
    }
    #item_detail .freearea .heading01 {
        margin-bottom: 16px;
    }
    /* review_area */
    #review_area {
        border-top: none;
        padding-bottom: 16px;
    }
    #review_area dl {
        padding-bottom: 32px;
    }
    #review_area dl dt {
        padding: 16px;
    }
    #review_area dl dt .cb {
        position: absolute;
        right: 32px;
        top: 24px;
        font-size: 16px;
        font-size: 1.6rem;
    }
    #review_area dl dd {
        display: block;
        padding: 16px 0 0;
    }
    #review_area dl dd .review_btn {
        padding: 16px 0 0;
        text-align: center;
    }
    #review_area dl dd .review_btn a {
        width: auto;
        min-width: 350px;
    }
    #review_area .review_list {
        padding: 16px 0 0;
    }
    #review_area .review_list li {
        padding: 16px 0;
    }
    #review_area .review_list p {
        margin: 6px 0;
    }
    /* keyword_area */
    #keyword_area {
        border: 0 none;
    }
    #keyword_area .heading03 {
        border-top: 1px solid #E8E8E8;
        margin-top: 0;
        padding-top: 18px;
    }
    #keyword_area ul li a {
        font-size: 14px;
        font-size: 1.4rem;
    }
    #keyword_area .keyword_list {
        padding-bottom: 16px;
    }
    /* recommend_area */
    #recommend_area {
        border: 0 none;
    }
    #recommend_area .heading03 {
        border-top: 1px solid #E8E8E8;
        margin-top: 0;
        padding-top: 18px;
    }
    #recommend_area .carousel .product_item .btn_circle {
        right: 24px;
    }
    #recommend_area .carousel {
        margin-left: -16px;
        margin-right: -16px;
    }
    #recommend_area .carousel .product_item {
        padding: 0 16px;
    }
    #recommend_area .slick-next,
    #recommend_area .slick-prev {
        font-size: 37px;
        font-size: 3.7rem;
        top: -1.4em;
    }
    #recommend_area .slick-next {
        right: 14px;
    }
    #recommend_area .slick-prev {
        right: 74px;
    }
    #recommend_area .angle-circle {
        width: 35px;
        height: 35px;
        margin-top: 0;
    }
    #recommend_area .slick-prev .cb,
    #recommend_area .slick-next .cb {
        fill: #fff;
        position: relative;
        top: -4px;
        right: -2px;
        font-size: 25px;
        font-size: 2.5rem;
    }
    /* Cart Page
    ------------------------------------------- */
    .total_box {
        min-width: 256px;
        float: right;
        padding: 0;
    }
    .total_box dl {
        margin-left: 0;
    }
    .total_box .btn_group {
        padding: 16px 0;
    }
    .total_box .btn_group p {
        margin: 16px 0;
    }
    .total_box dl.total_price dt {
        padding-top: 0.3em;
    }
    .total_box dl.total_price dd {
        font-size: 24px;
        font-size: 2.4rem;
    }
    .cart_item {
        margin: 24px 0 16px;
        border-top: 0 none;
    }
    .cart_item .item_box .table {
        width: 58.33333333%;
    }
    .cart_item .item_quantity::before {
        content: "";
    }
    .cart_item.table .tbody .tr>.item_quantity {
        text-align: center;
    }
    .cart_item .item_detail .item_subtotal {
        display: none;
    }
    .cart_item.table .tbody .tr div.item_subtotal {
        display: table-cell;
        text-align: right;
    }
    .cart_item .item_box .table .item_photo {
        min-width: 160px;
        padding-right: 16px;
    }
    .column p {
        margin: 16px 0;
    }
    .column .form-inline input,
    .column .form-inline select {
        margin-bottom: 16px;
    }
    div.table .tbody .addr_box .column {
        text-align: left;
        position: relative;
    }
    .addr_box .column.is-edit .btn_edit {
        top: 16px;
    }
    .multiple_column .item_box>div,
    .multiple_column .item_box>dl {
        padding: 16px 0;
        vertical-align: middle;
    }
    .multiple_column .item_box .item_photo {
        padding: 16px;
        max-width: 198px;
    }
    .multiple_column .item_box .item_detail .item_price {
        margin: 8px 0 16px;
    }
    .multiple_column .form-group {
        margin-top: 16px;
    }
    .complete_message {
        text-align: center;
        p {
            width: 70%;
            margin: 0 auto 50px;
            text-align: left;
        }
    }
    .complete_message .heading01 {
        font-size: 32px;
        font-size: 3.2rem;
    }
    #confirm_side {
        top: 0;
    }
    /* payment_list */
    .payment_list .radio {
        display: inline;
        margin-right: 16px;
        vertical-align: middle;
    }
    .payment_list .radio input[type="radio"] {
        margin-right: 10px;
    }
    .payment_list li img {
        margin-top: 0;
        vertical-align: middle;
    }
    /* MyPage
    ------------------------------------------- */
    /* ナビゲーション項目が6件の場合
    .local_nav li {
        width: 16.66666667%
    }*/
    .local_nav li {
        width: 25%;
    }
    .local_nav.favorite li {
        width: 20%;
    }
    .historylist_column {
        border-top: 1px solid #e0e0e0;
        margin: 0;
    }
    .historylist_column .order_date {
        font-size: 20px;
        font-size: 2rem;
        margin-top: 16px;
    }
    .historylist_column .table.item_box:first-child {
        border-top: 0 none;
    }
    .historylist_column .item_box .item_photo {
        min-width: 130px;
    }
    .historylist_column+.pagination {
        padding-top: 16px;
    }
    .unsubscribe_box h3 {

        font-size: 2.4rem;
    }
    .message_box h1 {
        font-size: 2.4rem;
    }


    /****** Effect Setting ********/
    /* Overlay */
    .overlay {
        display: none;
    }
    /****** Each BlockParts Setting ********/
    /* Cart */
    .cart {
        /*height: 120px;
        top: 44px;*/
    }
    /* Search */
    .bt_search {
        border: 0;
        background: none;
        display: block;
        padding: 0;
    }

    .bt_search .cb-search {
        fill: #fff;
        font-size: 1.5rem;
    }
    /* ----- category_nav in header  ---- */
    #search_box {
        float: right;
        width: 50%;
        dl {
            color: #FFF;
            text-align: right;
            position: relative;
        }
        dt.accordion {
            line-height: 30px;
            color: #FFF;
        }
        dt.accordion.opened {
            background-image: url(icon.png), url(bg_bar.png);
            background-repeat: no-repeat, no-repeat;
            background-position: 0% 0%, 0% 0%;
            -webkit-background-size: 0% 0%, 100%;
        }
        dd {
            width: 100%;
            background: url(icon02.png) no-repeat;
            position: absolute;
            top: 40px;
            left: 0;
        }
        dd ul li {
            list-style: none;
            height: 20px;
            line-height: 30px;
            background: #09F;
        }
        #search {
            background: none;
            padding: 0;
            width: inherit;
            float: none;
        }
        .cb {
            font-size: 2.6rem;
        }
    }
    /* ----- category_nav in side ---- */
    .side #searchform select,
    .side #searchform input {
        float: none;
    }
    /* Member area */
    .member ul li {
        float: right;
        width: 20px;
        margin: 0 13px 0 0;
    }

    .member_link a {
        border: none;
        color: #fff;
        display: block;
        font-size: 1.4rem;
        height: 25px;
        line-height: 25px;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;
        transform: translateZ(0px);
        transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    }

    .member_link a:hover {
        color: #fff;
    }

    .member_link a .cb {
        font-size: 2rem;
        fill: #fff;
        position: relative;
        top: -1px;
        margin: 0;
    }

    .ico_entry {
        fill: #fff;
    }
    /****** Each Page Setting ********/
    /* Item List Page
    ------------------------------------------- */
    /* Item Detail Page
    ------------------------------------------- */
    /* Cart Page
    ------------------------------------------- */
    .cart_page .dl_table {
        margin: 24px 0 32px;
    }

    .cart_page .dl_table dt {
        margin-bottom: 0;
    }

    #shopping_confirm .cart_item.table {
        border-top: 1px dotted #e0e0e0;
    }

    #shopping_confirm .column .cart_item.table {
        margin-top: 0;
    }

    #shopping_confirm .total_box {
        margin-top: 24px;
        padding: 16px;
        min-width: 0;
        width: 100%;
        float: none;
    }

    #shopping_confirm .total_box dl {
        padding-top: 8px;
    }

    #shopping_confirm .total_box dl:first-child {
        padding-top: 0;
    }

    #shopping_confirm .total_box dl dt {
        font-size: 14px;
        font-size: 1.4rem;
    }

    #shopping_confirm .total_box .total_amount {
        margin-top: 16px;
    }

    #shopping_confirm .total_box .total_amount p.total_price {
        font-size: 14px;
        font-size: 1.4rem;
    }

    #shopping_confirm .total_box .total_amount p.total_price strong {
        font-size: 24px;
        font-size: 2.4rem;
    }

    #shopping_confirm .total_box .total_amount p.total_price strong .small {
        font-size: 14px;
        font-size: 1.4rem;
    }

    #shopping_confirm .heading02 {
        font-size: 20px;
        font-size: 2rem;
        margin-top: 32px;
    }

    #shopping_confirm .cart_item .item_box .table .item_photo {
        min-width: 80px;
        max-width: 96px;
    }

    #deliveradd_input .dl_table {
        border-top: 1px dotted #e0e0e0;
    }
    /* MyPage
    ------------------------------------------- */
    .mypage .intro {
        padding-bottom: 16px;
    }

    .mypage #shopping_confirm .cart_item,
    .mypage #shopping_confirm .total_box {
        margin-top: 0;
    }

    .mypage #item_list .product_item {
        padding-bottom: 24px;
    }

    .mypage .title_hint {
        margin: 24px 0 0;
    }

    .mypage .title_hint+.form-group {
        padding-top: 8px;
    }
    /* My page address */
    #delivery_address_list div.is-edit p.btn_edit {
        top: 30%;
    }
    /* Registration Page
    ------------------------------------------- */
    .registration_page .dl_table {
        margin: 24px 0 32px;
    }
    /* Other Page
    ------------------------------------------- */
    /* Login Page */
    #login_box .column {
        padding: 16px 16px 40px;
    }

    #login_box .column p {
        margin-bottom: 8px;
    }

    #login_box .column .form-group {
        padding: 8px 0 8px 13%;
    }

    #login_box .column .btn_area p {
        float: left;
        width: 100%;
        min-width: 195px;
    }

    #login_box .column .btn_area ul {
        margin-top: 0;
    }

    #login_box .column .btn_area button {
        width: 100%;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    #login_box .column .btn_area ul {
        float: left;
    }

    #login_box .column .btn_area ul li {
        margin: 4px 0;
    }

    #login_box .column input[type="text"],
    #login_box .column input[type="email"],
    #login_box .column input[type="password"] {
        width: 85%;
    }

    #login_box .column .btn_area,
    #login_box .column .message {
        padding: 8px 13%;
        margin: 0;
    }

    .login_cart .column .column_inner {
        /* position: relative; */
        /* top: 50%; */
        /*-ms-transform: translateY(-50%);*/
        /* transform: translateY(-50%); */
    }
    /* Forgot Page */
    #forgot_page .column {
        padding: 32px;
        margin-bottom: 16px;
    }

    #forgot_page .column .form-group {
        padding: 8px 0 8px 11%;
        width: 89%;
    }

    #forgot_page .column .btn_area {
        padding: 20px 0 0 21%;
        width: 79%;
    }

    #forgot_page .column .message {
        margin-bottom: 16px;
    }
    /* Error Page */
    .error_page .footer_logo_area {
        margin-top: 0;
    }
    /*テンプレート追加*/
    #pick_up {

    }

    #shopping_flow h3,
    #shopping_guide h3 {
        background: #333;
        color: #fff;
        padding: 1% 10px;
    }

    #shopping_flow ol {
        width: 100%;
        border: 1px solid #e0e0e0;
    }

    #shopping_flow ol li:nth-child(1) {
        border: none;
    }

    #shopping_flow ol li {
        float: left;
        width: 16.6%;
        padding: 0 1%;
        border-left: solid 1px #e0e0e0;
        font-size: 100%;
    }

    #shopping_flow .big02 {
        font-size: 150%;
        font-weight: bold;
    }

    #shopping_flow .red {
        color: #E31919;
    }

    #shopping_flow h4 {
        color: #FF7782;
        font-size: 110%;
        border-bottom: dotted 2px #e0e0e0;
        padding: 0 0 10px 0;
    }

    #shopping_flow .step {
        font-size: 90%;
        background: url(../img/icon/arrow3.png) no-repeat right center;
        padding: 0 20px 0 0;
        color: #333;
    }

    #shopping_guide {
        clear: both;
    }

    #shopping_guide .guide_01 {
        float: left;
        width: 48%;
    }

    #shopping_guide .guide_02 {
        float: right;
        width: 48%;
    }

    #shopping_guide h4 {
        padding: 5px 18px;
        background: url(../img/icon/circle.gif) no-repeat left center;
        border-bottom: dotted #b2b2b2 1px;
        font-weight: bold;
    }

    #shopping_guide p {
        margin-bottom: 20px;
    }

    #shopping_guide table {
        margin: 15px auto 20px auto;
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        th {
            padding: 8px;
            border-right: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            color: #333;
            background-color: #f0f0f0;
            font-weight: normal;
        }

        td {
            padding: 8px;
            border-right: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            word-break: break-all;
        }
    }
    .sns {
        float: right;
        width: 15%;
        ul {
            li {
              float: left;
              width: 20%;
              margin: 0 0 0 10%;
              svg {
                width: 80%;
                height: 50px;
            }
        }
    }
}
#footer {
 margin: 0;
 padding: 30px 0 0 0;
 background: rgba(149, 184, 176, 0.85);
 .pc_inn{
  border-bottom: solid 1px #fff;
  padding: 0 0 10px 0;
}
.footer_top {
    width: 100%;
    padding: 0 0 20px 0;
}


.footer_logo {
    margin: 0;
}
.footer_link {

    ul {
      float: left;
      width: 78%;
      padding: 10px 0;
  }
  li:nth-child(2n) {
    padding: 0;
}
li:first-child {
    border: none;
    padding: 0;
}
li {
    font-size: 1.3rem;
    border-left: solid 1px #fff;
    margin: 0;
    float: left;

    a {
        font-size: 1.3rem;
        padding: 0 10px;
    }
}
}
}

}

@media #{$not_sp} {
    /****** Footer Setting ********/

}

@media #{$tab} {
    /****** drawer Setting ********/
    .drawer {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 40%;
        background: #F6F6F6;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 1;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }
    .drawer-open #contents,
    .drawer-open #header,
    .drawer-open #footer,
    .drawer-open .pagetop {
        -webkit-transform: translateX(-40%);
        -moz-transform: translateX(-40%);
        -ms-transform: translateX(-40%);
        -o-transform: translateX(-40%);
        transform: translateX(-40%);
    }
    /* Overlay */
    .overlay.is-visible {
        -webkit-transform: translateX(-40%);
        -moz-transform: translateX(-40%);
        -ms-transform: translateX(-40%);
        -o-transform: translateX(-40%);
        transform: translateX(-40%);
    }
    .overlay.is-visible.cart-is-visible {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}